<template>
        <div class="custom-pagination" v-if="pagination.page_total > 1">
                <nav>
                        <ul>
                                <li v-if="pagination.current_page > 1">
                                        <a href="javascript:void(0)" @click="changePage(pagination.current_page - 1)">
                                                <img src="~/assets/images/client/ic_arrow_left_gray.svg" />
                                        </a>
                                </li>
                                <li v-for="page in pagesNumber" :class="{ 'active': page == pagination.current_page }">
                                        <a href="javascript:void(0)" @click="changePage(page)">{{ page }}</a>
                                </li>
                                <li v-if="pagination.current_page < pagination.page_total">
                                        <a href="javascript:void(0)" @click="changePage(pagination.current_page + 1)">
                                                <img src="~/assets/images/client/ic_arrow_right_gray.svg" />
                                        </a>
                                </li>
                        </ul>
                </nav>
                <p v-if="pagination.total_items > 0">Showing {{ startCnt }} to {{ endCnt }} items out of {{
                        pagination.total_items }} {{ pagination.total_items > 1 ? 'entries' : 'entry' }}</p>
        </div>
</template>

<script>
export default {
        props: {
                pagination: {
                        type: Object,
                        required: true
                },
                offset: {
                        type: Number,
                        default: 4
                }
        },
        computed: {
                pagesNumber() {
                        let from = this.pagination.current_page - this.offset;
                        if (from < 1) {
                                from = 1;
                        }
                        let to = from + (this.offset * 2);
                        if (to >= this.pagination.page_total) {
                                to = this.pagination.page_total;
                        }
                        let pagesArray = [];
                        for (let page = from; page <= to; page++) {
                                pagesArray.push(page);
                        }
                        return pagesArray;
                },
                startCnt() {
                        let start_cnt = ((this.pagination.current_page * this.pagination.per_page) - this.pagination.per_page) + 1;
                        return start_cnt;
                },
                endCnt() {
                        let end_cnt = 0;
                        if ((this.pagination.current_page * this.pagination.per_page) > this.pagination.total_items) {
                                end_cnt = this.pagination.total_items;
                        } else {
                                end_cnt = this.pagination.current_page * this.pagination.per_page;
                        }
                        return end_cnt;
                }
        },
        methods: {
                changePage: function (page) {
                        this.pagination.current_page = page;
                        this.$emit('paginate');
                }
        },
        mounted() {
                this.$emit('paginate');
        }
}
</script>
